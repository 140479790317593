import React from 'react';
import { Typography } from 'antd';
const { Title } = Typography;

import './tagline.scss';

/* eslint-disable-next-line */
export interface TaglineProps {
  tagline: string;
  undertag?: any;
}

export const Tagline = (props: TaglineProps) => {
  return (
    <div>
      <div style={{ textAlign: 'center', padding: '12px', marginTop: '10px' }}>
        <Title level={4}>{props.tagline}</Title>
      </div>
      {props.undertag && (
        <div style={{ textAlign: 'center', padding: '6px' }}>
          {props.undertag}
        </div>
      )}
    </div>
  );
};

export default Tagline;
