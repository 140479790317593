import React from 'react';
import { Row, Col, Typography, Divider, Button } from 'antd';
const { Paragraph, Text, Title } = Typography;

export const amentities = {
  sentence: <div style={{fontSize: '18px', lineHeight: '18px', textAlign: 'center'}}>
    <Paragraph><Text mark strong>Great location</Text> close to restaurants, shops & more!</Paragraph>
    <Paragraph>Private <Text mark strong>gym</Text> and <Text mark strong>roof garden</Text>.</Paragraph>
    <Paragraph>Covered and secure <Text mark strong>parking</Text>.</Paragraph>
    <Paragraph>Intercom entry and <Text mark strong>secure access</Text>.</Paragraph>
    {/* <Paragraph>Professional <Text mark strong>&nbsp;manager next door&nbsp;</Text>.</Paragraph> */}
    <Paragraph><Text mark strong>Electrical Vehicle Chargers</Text> Available</Paragraph>
    <Paragraph>Cat friendly, no dogs.</Paragraph>
  </div>,
  images: [
    {
      src: 'assets/pics/v2/courtyard.jpg',
      caption: '',
      thumb: 'assets/pics/v2/courtyard.jpg'
    },
    {
      src: 'assets/pics/v2/gym.jpg',
      caption: '',
      thumb: 'assets/pics/v2/gym.jpg'
    },
    {
      src: 'assets/pics/v2/parking.jpg',
      caption: '',
      thumb: 'assets/pics/v2/parking.jpg'
    },
    {
      src: 'assets/pics/v2/roof.jpg',
      caption: '',
      thumb: 'assets/pics/v2/roof.jpg'
    }
  ]
}
