import React, { Fragment } from 'react';

import { Row, Col, Typography, Divider, Button } from 'antd';

import { ApplicationData } from '@bwb-buildings-mono/entities';
import * as UI from '@bwb-buildings-mono/ui';

import { features } from './features';
import { amentities } from './ammenities';

const { Paragraph, Text, Title } = Typography;

const PHONE_NUMBER = '+1(213)212-7655';
const EMAIL = 'manager@730kingsley.com';
const ADDRESS_NAME = '730 Kingsley Apartments';
const ADDRESS_1 = '730 S. Kingsley Drive';
const ADDRESS_2 = 'Los Angeles, CA 90005';

const tours = [
  {
    name: "Lobby / Mailbox / Manager's Office / Gym / Garage",
    url: 'https://my.matterport.com/show/?model=CGcumm8XwDg&play=1',
  },
  {
    name: 'Roofdeck',
    url: 'https://my.matterport.com/show/?model=GxQoZEtt4B4&play=1',
  },
  {
    name: '2 Bed, 2 Bath',
    url: 'https://my.matterport.com/show/?model=PPBawJR94E8&play=1',
  },
  {
    name: '1 Bed, 1 Bath',
    url: 'https://my.matterport.com/show/?model=G6PqvhpnyTy&play=1',
  },
  {
    name: '1 Bed, 1 Bath Layout 2',
    url: 'https://my.matterport.com/show/?model=kxGxRqHskcX&play=1',
  },
  {
    name: 'Studio, 1 Bath',
    url: 'https://my.matterport.com/show/?model=2fBnG939Au7&play=1',
  },
];

export const AppData: ApplicationData = {
  footer: (
    <div>
      <div>
        <Row>
          <Col md={24} xs={0}>
            {ADDRESS_NAME} — {ADDRESS_1}, {ADDRESS_2} —{' '}
            <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
          </Col>
          <Col md={0} xs={24}>
            {ADDRESS_NAME}
            <br />
            {ADDRESS_1}
            <br />
            {ADDRESS_2}
            <br />
            <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
          </Col>
        </Row>
      </div>
      <div style={{ fontSize: '80%', marginTop: '24px' }}>
        <div>
          {ADDRESS_NAME} is located in or around Koreatown, Wilshire Center,
          Wilshire Park, Central LA, Macarthur Park, Los Angeles
        </div>
        <div>Thank You For Looking, We Look Forward to Helping You!</div>
      </div>
    </div>
  ),
  sections: [
    {
      element: UI.Logo,
      props: {
        logo: (
          <img style={{ height: '50px' }} src="./assets/Logo-H-Color.png" />
        ),
        address: (
          <div style={{ lineHeight: 1.2 }}>
            <div style={{ fontSize: '110%' }}>{ADDRESS_NAME}</div>
            <div style={{ fontSize: '80%' }}>{ADDRESS_1}</div>
            <div style={{ fontSize: '80%' }}>{ADDRESS_2}</div>
            <div style={{ fontSize: '80%' }}>
              <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
            </div>
          </div>
        ),
      },
    },
    {
      isNav: true,
      element: UI.Nav,
      props: {
        logo: {
          logo: <img style={{ height: '50px' }} src="./assets/logo.png" />,
          address: (
            <div style={{ lineHeight: 1 }}>
              <div style={{ fontSize: '110%' }}>{ADDRESS_NAME}</div>
              <div style={{ fontSize: '80%' }}>{ADDRESS_1}</div>
              <div style={{ fontSize: '80%' }}>{ADDRESS_2}</div>
              <div style={{ fontSize: '80%' }}>{PHONE_NUMBER}</div>
            </div>
          ),
        },
        leftSide: <Fragment />,
        rightSide: (
          <Fragment>
            <UI.VirtualToursButton tours={tours} />
            <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL} />
          </Fragment>
        ),
        rightSideModal: (
          <div>
            <Paragraph>
              <UI.VirtualToursButton tours={tours} />
            </Paragraph>
            <Paragraph>
              <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL} />
            </Paragraph>
          </div>
        ),
      },
    },
    {
      element: UI.HeroImage,
      props: {
        backgroundSize: 'contain',
        image: './assets/pics/v2/front-edited.jpg',
        height: '60vmin',
      },
    },
    {
      element: UI.Tagline,
      props: {
        tagline: 'New apartments in central Koreatown, Los Angeles.',
        undertag: (
          <div>
            <UI.VirtualToursButton tours={tours} />
            <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL} />
          </div>
        ),
      },
    },
    {
      element: UI.SectionTextCarousel,
      label: UI.NAV_ITEM.UNITS,
      nav: 'Units',
      props: {
        title: 'Quality Apartments',
        sections: [features],
      },
    },
    {
      element: UI.SectionTextCarousel,
      label: UI.NAV_ITEM.AMENITIES,
      nav: 'Amenities',
      props: {
        title: 'Community Amenities',
        sections: [amentities],
      },
    },
    {
      element: UI.SectionImages,
      label: UI.NAV_ITEM.FLOORPLANS,
      nav: 'Floorplans',
      props: {
        title: 'Floorplans',
        images: [
          './assets/floorplans/730K - Unit Type 1B.jpg',
          './assets/floorplans/730K - Unit Type 1C.jpg',
          './assets/floorplans/730K - Unit Type 1D.jpg',
          './assets/floorplans/730K - Unit Type 2C.jpg',
        ],
      },
    },
    {
      element: UI.SectionSideBySide,
      props: {
        leftSide: (
          <div>
            <h3>Schedule a tour with our on-site manager:</h3>
            <div style={{ marginTop: 24 }}>
              <h1 style={{}}>
                <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
              </h1>
              <div style={{ fontSize: '80%' }}>Call anytime!</div>
            </div>
            <div style={{ marginTop: 24 }}>
              <h1 style={{}}>
                <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
              </h1>
              <div style={{ fontSize: '80%' }}>
                Email us what you are looking for and some times that work for
                you.
              </div>
            </div>
            <div style={{ marginTop: 24 }}>
              <Paragraph>
                <UI.VirtualToursButton tours={tours} />
              </Paragraph>
              <Paragraph>
                <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL} />
              </Paragraph>
            </div>
          </div>
        ),
        rightSide: (
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.42455800421!2d-118.30486958454222!3d34.058629324840346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2b883d15dbbdf%3A0x53b8fff582baafcf!2s730%20S%20Kingsley%20Dr%2C%20Los%20Angeles%2C%20CA%2090005!5e0!3m2!1sen!2sus!4v1647548796584!5m2!1sen!2sus"
            width="400"
            height="400"
            frameBorder={0}
            style={{ border: '1px solid #CCC' }}
          ></iframe>
        ),
      },
    },
  ],
};
